<template>
  <div class="merch">
    <el-form ref="form" :model="form" :rules="formRule" label-width="200px">
      <el-form-item label="提现手续费扣除方式：" prop="withdrawRatio">
        <span>按比例扣除</span>
        <el-input
          type="number"
          style="width: 200px; margin-right: 12px; margin-left: 12px"
          v-model="form.withdrawRatio"
          oninput="if(value > 100) value = ''; if(value < 0  || value == null) value = '';"
          @input="valueChange(form.withdrawRatio)"
          placeholder="请输入"
        ></el-input
        ><i>%</i>
      </el-form-item>
      <div>
        <el-form-item
          label="业务员佣金策略配置："
          prop="monthWithdrawNumberType"
        >
          <el-checkbox
            v-model="form.monthWithdrawNumberType"
            @change="NumberType"
            :false-label="0"
            :true-label="1"
            label="业务员佣金每月能提现"
          />
          <el-input
            type="number"
            :maxlength="6"
            v-model.number="form.monthWithdrawNumber"
            oninput=" if(value < 0 || value == null) value = '';"
            style="width: 220px; margin-right: 12px; margin-left: 12px"
            placeholder="请输入内容"
            autocomplete="off"
            :error="'输入长度不能超过6个字符'"
            :disabled="this.monthWithdraw != 1"
          />次（按自然月计算）
        </el-form-item>
        <el-form-item prop="withdrawAmountStandardType">
          <el-checkbox
            v-model="form.withdrawAmountStandardType"
            @change="StandardType"
            :false-label="0"
            :true-label="1"
            label="业务员佣金金额达到"
          />
          <el-input
            type="number"
            :maxlength="6"
            v-model.number="form.withdrawAmountStandard"
            autocomplete="off"
            oninput=" if(value < 0 || value == null) value = '';"
            style="width: 220px; margin-right: 12px; margin-left: 12px"
            placeholder="请输入内容"
            :error="'输入长度不能超过6个字符'"
            :disabled="this.withdrawAmount != 1"
          />元，才能进行提现
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('form')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("settlement");
export default {
  data() {
    var NumberTypeValue = (rule, value, callback) => {
      console.log(this.monthWithdraw, value, "this.monthWithdraw");
      if (this.monthWithdraw == 0) {
        return callback();
      }
      // if (value == false) {
      //   return callback(new Error("请至少选择一项"));
      // }
    };
    // StandardType
    var StandardTypeValue = (rule, value, callback) => {
      console.log(this.withdrawAmount, value, "this.withdrawAmount");
      if (this.withdrawAmount == 0) {
        return callback();
      }
      // if (value == false) {
      //   return callback(new Error("请至少选择一项"));
      // }
    };
    return {
      typeVal: [],
      admin_id: sessionStorage.getItem("admin_id"),
      operator: this.$cookie.get("newUserName"),
      form: {
        type: this.typeVal,
        businessId: "", //供应商ID
        withdrawCommissionType: "", // 提现手续费类型
        withdrawRatio: "", //手续费比例
        monthWithdrawNumber: "", //月提现次数
        withdrawAmountStandard: "", //提现金额标准
        operatorId: "", //操作人id
        operator: "", //操作人姓名
        monthwithdrawNumberType: true,
        withdrawAmountStandardType: true,
      },
      monthWithdraw: null,
      withdrawAmount: null,
      formRule: {
        withdrawRatio: [
          { required: true, message: "请输入扣除比例", trigger: "blur" },
        ],
        monthWithdrawNumberType: [
          { validator: NumberTypeValue, trigger: "blur" },
        ],
        withdrawAmountStandardType: [
          { validator: StandardTypeValue, trigger: "blur" },
        ],
      },
    };
  },

  created() {
    this.WithdrawConfig();
  },
  methods: {
    ...mapActions(["getQueryWithdrawConfig", "saveWithdrawConfig"]),

    //获取业务员佣金提现配置monthWithdrawNumberType
    async WithdrawConfig() {
      let res = await this.getQueryWithdrawConfig();
      if (res.code == "000000") {
        this.form = res.content;
        if (this.form != null) {
          this.monthWithdraw = this.form.monthWithdrawNumberType;
          this.withdrawAmount = this.form.withdrawAmountStandardType;
        } else {
          this.monthWithdraw = "";
          this.withdrawAmount = "";
        }
        if (this.form.monthWithdrawNumberType == 0) {
          this.form.monthWithdrawNumberType = false;
        } else {
          this.form.monthWithdrawNumberType = true;
        }
        if (this.form.withdrawAmountStandardType == 0) {
          this.form.withdrawAmountStandardType = false;
        } else {
          this.form.withdrawAmountStandardType = true;
        }
      } else {
        this.$message({
          message: res.message,
          type: "error",
        });
      }
    },
    // 业务员佣金每月能提现的状态
    NumberType(value) {
      this.monthWithdraw = value;
      if (value == 0) {
        this.form.monthWithdrawNumber = "";
      }
    },
    // 业务员佣金金额达到
    StandardType(value) {
      this.withdrawAmount = value;
      if (value == 0) {
        this.form.withdrawAmountStandard = "";
      }
    },
    // 提交
    async onSubmit(form) {
      // if (
      //   this.form.monthWithdrawNumber == "" ||
      //   this.form.withdrawAmountStandard == "" ||
      //   this.form.withdrawRatio == ""
      // ) {
      //   this.$message({
      //     message: "请将带星号的填写完成",
      //     type: "warning",
      //   });
      //   return;
      // }
      // if( this.form.withdrawAmountStandard == "" && this.form.monthWithdrawNumber == ""){
      //   this.$message({
      //     message: "业务员佣金策略配置必填一项",
      //     type: "warning",
      //   });
      //   return;
      // }
      this.formData = {
        // businessId: this.form.businessId,
        withdrawCommissionType: this.form.withdrawCommissionType,
        withdrawRatio: this.form.withdrawRatio,
        monthWithdrawNumber: this.form.monthWithdrawNumber + "",
        withdrawAmountStandard: this.form.withdrawAmountStandard + "",
        operatorId: this.admin_id,
        operator: this.operator,
        monthWithdrawNumberType: this.monthWithdraw,
        withdrawAmountStandardType: this.withdrawAmount,
      };
      let res = await this.saveWithdrawConfig(this.formData).then((res) => {
        if (res.code == "000000") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.WithdrawConfig();
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
      });
      // this.$refs[form].validate((valid) => {
      //   if (valid) {
      //     console.log(valid,'---valid---')
      //     this.saveWithdrawConfig(this.formData)
      //       .then((res) => {
      //         if (res.code == "000000") {
      //           this.$message({
      //             message: res.message,
      //             type: "success",
      //           });
      //           this.WithdrawConfig();
      //         } else {
      //           this.$message({
      //             message: res.message,
      //             type: "error",
      //           });
      //         }
      //       })
      //       .catch((err) => {
      //         this.$message({
      //           message: res.message,
      //           type: "warning",
      //         });
      //       });
      //   } else {
      //     console.log("错误");
      //     return false;
      //   }
      // });
    },

    //严正el-input保留两位
    valueChange(e) {
      let val = e.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/, "$1");
      if (val == null || val == undefined || val == "") {
        val = "";
      }
      this.form.withdrawRatio = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item .el-form-item {
  margin-bottom: 28px;
}
.el-form-item.el-form-item__content.el-checkbox__label {
  color: #2f2f2f !important;
}
.el-checkbox__label {
  color: #2f2f2f !important;
}
</style>
